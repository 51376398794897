<template>
  <div class="Partner">
    <Header back="返回" title="合作客户" index="首页" titleOne="合同管理" titleTwo="合作客户" beforeTitle="编辑" />
    <div class="content">
      <h1>客户信息</h1>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="info">
          <el-form
            :rules="baseRule"
            ref="baseRule"
            :model="baseform"
            label-width="80px"
            :label-position="labelPosition"
          >
            <el-form-item label="企业名称" prop="company_name">
              <el-input v-model="baseform.company_name" placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label="企业曾用名" prop="short_name">
              <el-input v-model="baseform.short_name" placeholder="请输入企业曾用名"></el-input>
            </el-form-item>
            <el-form-item label="组织机构代码" prop="org_code">
              <el-input v-model="baseform.org_code" placeholder="请输入组织机构代码"></el-input>
            </el-form-item>
            <el-form-item label="信用代码" prop="code">
              <el-input v-model="baseform.code" placeholder="请输入信用代码"></el-input>
            </el-form-item>
            <el-form-item label="注册资本（万元）" prop="register_capital">
              <el-input v-model="baseform.register_capital" placeholder="请输入注册资本"></el-input>
            </el-form-item>
            <el-form-item label="注册时间" prop="register_time">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                v-model="baseform.register_time"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="是否高企">
              <el-select v-model="baseform.is_high" placeholder="是否高企" @change="isBase">
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="高企重新认定时间" prop="reassessment_time" v-if="baseStatus">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                v-model="baseform.reassessment_time"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <div class="clear"></div>
            <el-form-item label="所属区域" prop="province_id">
              <el-select v-model="baseform.province_id" placeholder="请选择省" @change="getcity">
                <el-option
                  v-for="item in province"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="city_id">
              <el-select v-model="baseform.city_id" placeholder="请选择市" @change="gettown">
                <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="town_id">
              <el-select v-model="baseform.town_id" placeholder="请选择区" @change="getstreet">
                <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="baseform.block_id" placeholder="请选择镇/模块">
                <el-option
                  v-for="item in street"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业类型" prop="com_type">
              <el-select v-model="baseform.com_type" placeholder="请选择企业类型">
                <el-option label="有限责任公司" :value="1">有限责任公司</el-option>
                <el-option label="股份有限公司" :value="2">股份有限公司</el-option>
                <el-option label="国企" :value="3">国企</el-option>
                <el-option label="外商投资企业" :value="4">外商投资企业</el-option>
                <el-option label="独资企业" :value="5">独资企业</el-option>
                <el-option label="个体工商户" :value="6">个体工商户</el-option>
                <el-option label="联营企业" :value="7">联营企业</el-option>
                <el-option label="集体所有制" :value="8">集体所有制</el-option>
                <el-option label="有限合" :value="9">有限合伙</el-option>
                <el-option label="普通合伙" :value="10">普通合伙</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经营状态" prop="status">
              <el-select v-model="baseform.status" placeholder="请选择经营状态">
                <el-option label="在业" :value="1">在业</el-option>
                <el-option label="存续" :value="2">存续</el-option>
                <el-option label="正常" :value="3">正常</el-option>
                <el-option label="注销" :value="4">注销</el-option>
                <el-option label="筹建" :value="5">筹建</el-option>
                <el-option label="清算" :value="6">清算</el-option>
                <el-option label="迁入" :value="7">迁入</el-option>
                <el-option label="迁出" :value="8">迁出</el-option>
                <el-option label="停业" :value="9">停业</el-option>
                <el-option label="撤销" :value="10">撤销</el-option>
                <el-option label="吊销" :value="11">吊销</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属行业" prop="industry_one">
              <el-select
                v-model="baseform.industry_one"
                placeholder="请选择所属行业"
                @change="getSecondIndustry"
              >
                <el-option
                  v-for="item in oneindustry"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="industry_two">
              <el-select v-model="baseform.industry_two" placeholder="请选择所属行业类型">
                <el-option
                  v-for="item in twoindustry"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产规模" prop="asset_scale">
              <el-select v-model="baseform.asset_scale" placeholder="请选择资产规模">
                <el-option label="2000万元以内" :value="1">2000万元以内</el-option>
                <el-option label="2000万元以上 ~ 5000万元（含）" :value="2">2000万元以上 ~ 5000万元（含）</el-option>
                <el-option label="5000万元以上 ~ 1亿元（含）" :value="3">5000万元以上 ~ 1亿元（含）</el-option>
                <el-option label="1亿元以上 ~ 2亿元（含）" :value="4">1亿元以上 ~ 2亿元（含）</el-option>
                <el-option label="2亿元以上 ~ 4亿元（含）" :value="5">2亿元以上 ~ 4亿元（含）</el-option>
                <el-option label="4亿元以上" :value="6">4亿元以上</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司销售额（万元）">
              <el-input v-model="baseform.sales_scale" placeholder="请输入公司销售额"></el-input>
            </el-form-item>
            <el-form-item label="研发投入（万元）">
              <el-input v-model="baseform.yftr" placeholder="请输入研发投入"></el-input>
            </el-form-item>
            <div class="clear"></div>
            <el-form-item label="法人代表">
              <el-input v-model="baseform.legal_person" placeholder="请输入法人代表"></el-input>
            </el-form-item>
            <el-form-item label="业务经理">
              <el-input v-model="baseform.business_manager" placeholder="请输入业务经理"></el-input>
            </el-form-item>
            <el-form-item label="业务经理手机">
              <el-input v-model="baseform.tel" placeholder="请输入业务经理手机"></el-input>
            </el-form-item>
            <el-form-item label="客服管家">
              <el-input v-model="baseform.project_specialist" placeholder="请输入客服管家"></el-input>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input v-model="baseform.contact" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="固定电话">
              <el-input v-model="baseform.mobile" placeholder="请输入固定电话"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="baseform.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="企业网址">
              <el-input v-model="baseform.domain" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="专利数量">
              <el-input v-model="baseform.patent_count" placeholder="请输入专利数量"></el-input>
            </el-form-item>
            <el-form-item label="软著数量">
              <el-input v-model="baseform.soft_count" placeholder="请输入软著数量"></el-input>
            </el-form-item>
            <el-form-item label="商标数量">
              <el-input v-model="baseform.brand_count" placeholder="请输入商标数量"></el-input>
            </el-form-item>
            <el-form-item label="登记机关">
              <el-input v-model="baseform.register_org" placeholder="请输入登记机关"></el-input>
            </el-form-item>
            <el-form-item label="注册地址" style="width: 48%" placeholder="请输入注册地址">
              <el-input v-model="baseform.register_address"></el-input>
            </el-form-item>
            <el-form-item label="公司地址" style="width: 48%" placeholder="请输入公司地址">
              <el-input v-model="baseform.address"></el-input>
            </el-form-item>
            <el-form-item label="经营范围" style="width: 100%">
              <el-input
                type="textarea"
                v-model="baseform.jyfw"
                :autosize="{ minRows: 5, maxRows: 5 }"
                placeholder="请输入经营范围"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="foot_TJ">
            <el-button type="primary" @click="baseSubmit" :disabled="isDisable">确定</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人员资源" name="human">
          <el-form
            :rules="humanRule"
            ref="humanRule"
            :model="humanform"
            label-width="80px"
            :label-position="labelPosition"
          >
            <h2>总体情况</h2>
            <dl>
              <dt style="color: #404040">人员类型</dt>
              <dt style="color: #404040">企业职工（人）</dt>
              <dt style="color: #404040">科技人员（人）</dt>
            </dl>
            <dl>
              <dd>总人数</dd>
              <dd>
                <el-input v-model="peopleNum" placeholder="请输入企业总人数" disabled></el-input>
              </dd>
              <dd>
                <el-input v-model="humanNum" placeholder="请输入企业总人数" disabled></el-input>
              </dd>
            </dl>
            <dl>
              <dd>其中：在职人员</dd>
              <dd>
                <el-input v-model="humanform.q_zz" placeholder="请输入企业在职人数"></el-input>
              </dd>
              <dd>
                <el-input v-model="humanform.k_zz" placeholder="请输入企业在职人数"></el-input>
              </dd>
            </dl>
            <dl>
              <dd>兼职人员</dd>
              <dd>
                <el-input v-model="humanform.q_jz" placeholder="请输入企业兼职人数"></el-input>
              </dd>
              <dd>
                <el-input v-model="humanform.k_jz" placeholder="请输入企业兼职人数"></el-input>
              </dd>
            </dl>
            <dl>
              <dd>临时聘用人员</dd>
              <dd>
                <el-input v-model="humanform.q_ls" placeholder="请输入企业临时聘用人数"></el-input>
              </dd>
              <dd>
                <el-input v-model="humanform.k_ls" placeholder="请输入企业临时聘用人数"></el-input>
              </dd>
            </dl>
            <dl>
              <dd>外籍人员</dd>
              <dd>
                <el-input v-model="humanform.q_wj" placeholder="请输入企业外籍人数"></el-input>
              </dd>
              <dd>
                <el-input v-model="humanform.k_wj" placeholder="请输入企业外籍人数"></el-input>
              </dd>
            </dl>
            <dl>
              <dd>留学归国人员</dd>
              <dd>
                <el-input v-model="humanform.q_lx" placeholder="请输入企业留学归国人数"></el-input>
              </dd>
              <dd>
                <el-input v-model="humanform.k_lx" placeholder="请输入企业留学归国人数"></el-input>
              </dd>
            </dl>
            <dl>
              <dd>千人计划人员</dd>
              <dd>
                <el-input v-model="humanform.q_gr" placeholder="请输入企业千人计划人数"></el-input>
              </dd>
              <dd>
                <el-input v-model="humanform.k_gr" placeholder="请输入企业千人计划人数"></el-input>
              </dd>
            </dl>
            <h2>全体人员结构</h2>

            <el-form-item label="博士">
              <el-input v-model="humanform.x_bs" placeholder="请输入博士学历人数"></el-input>
            </el-form-item>
            <el-form-item label="硕士">
              <el-input v-model="humanform.x_ss" placeholder="请输入硕士学历人数"></el-input>
            </el-form-item>
            <el-form-item label="本科">
              <el-input v-model="humanform.x_bk" placeholder="请输入本科学历人数"></el-input>
            </el-form-item>
            <el-form-item label="大专及以下">
              <el-input v-model="humanform.x_dz" placeholder="请输入大专及以下学历人数"></el-input>
            </el-form-item>
            <el-form-item label="高级职称">
              <el-input v-model="humanform.z_gj" placeholder="请输入高级职称人数"></el-input>
            </el-form-item>
            <el-form-item label="中级职称">
              <el-input v-model="humanform.z_zj" placeholder="请输入中级职称人数"></el-input>
            </el-form-item>
            <el-form-item label="初级职称">
              <el-input v-model="humanform.z_cj" placeholder="请输入初级职称人数"></el-input>
            </el-form-item>
            <el-form-item label="高级技工">
              <el-input v-model="humanform.z_jg" placeholder="请输入高级技工人数"></el-input>
            </el-form-item>
            <el-form-item label="30岁及以下">
              <el-input v-model="humanform.l_one" placeholder="请输入30岁及以下人数"></el-input>
            </el-form-item>
            <el-form-item label="31岁-40岁">
              <el-input v-model="humanform.l_two" placeholder="请输入31岁-40岁人数"></el-input>
            </el-form-item>
            <el-form-item label="41岁-50岁">
              <el-input v-model="humanform.l_three" placeholder="请输入41岁-50岁人数"></el-input>
            </el-form-item>
            <el-form-item label="51岁及以上">
              <el-input v-model="humanform.l_four" placeholder="请输入51岁及以上人数"></el-input>
            </el-form-item>
            <h2>
              企业职工人数情况表
              <el-button class="Import">导出</el-button>
            </h2>
            <el-form-item label="全年月平均在职人员①">
              <el-input v-model="humanform.qly" placeholder="请输入全年月平均在职人员数"></el-input>
            </el-form-item>
            <el-form-item label="其中，参保人员">
              <el-input v-model="humanform.cbrs" placeholder="请输入参保人员数"></el-input>
            </el-form-item>
            <el-form-item label="工作时间超过183天的兼职人员②">
              <el-input v-model="humanform.jzrs" placeholder="请输入工作时间超过183天的兼职人员数"></el-input>
            </el-form-item>
            <el-form-item label="工作时间超过183天的临时聘用人员③">
              <el-input v-model="humanform.lsrs" placeholder="请输入工作时间超过183天的临时聘用人员数"></el-input>
            </el-form-item>
            <el-form-item label="企业职工总数（①+②+③）">
              <el-input v-model="humanform.qyzrs" placeholder="请输入企业职工总人员数"></el-input>
            </el-form-item>
            <el-form-item label="全年月平均在职人员数与参保人数差异的情况说明" style="width: 100%">
              <el-input
                type="textarea"
                v-model="humanform.qksm"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="foot_TJ">
            <el-button type="primary" @click="humanSubmit" :disabled="isDisable">确定</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import moment from "moment";
import site from "../../components/global.vue";
export default {
  data () {
    return {
      isDisable: false,
      userSite: site.userSite,
      activeName: "info",
      labelPosition: "top",
      input: "",
      //first
      baseform: {
        company_name: "",
        short_name: "",
        org_code: "",
        code: "",
        register_capital: "",
        register_time: "",
        is_high: "",
        province_id: "",
        city_id: "",
        town_id: "",
        block_id: "",
        com_type: "",
        status: "",
        industry_one: "",
        industry_two: "",
        asset_scale: "",
        sales_scale: "",
        yftr: "",
        legal_person: "",
        business_manager: "",
        tel: "",
        project_specialist: "",
        contact: "",
        mobile: "",
        email: "",
        domain: "",
        patent_count: "",
        soft_count: "",
        brand_count: "",
        register_org: "",
        register_address: "",
        address: "",
        jyfw: "",
        reassessment_time: "",//高企重新认定时间
      },
      baseStatus: 1,
      baseRule: {
        company_name: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入信用代码",
            trigger: "blur",
          },
        ],
        register_capital: [
          {
            required: true,
            message: "请输入注册资本",
            trigger: "blur",
          },
        ],
        register_time: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        province_id: [
          {
            required: true,
            message: "请选择省",
            trigger: "change",
          },
        ],
        city_id: [
          {
            required: true,
            message: "请选择市",
            trigger: "change",
          },
        ],
        town_id: [
          {
            required: true,
            message: "请选择区",
            trigger: "change",
          },
        ],
        com_type: [
          {
            required: true,
            message: "请选择企业类型",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择经营状态",
            trigger: "change",
          },
        ],
        industry_one: [
          {
            required: true,
            message: "请选择所属行业",
            trigger: "change",
          },
        ],
        industry_two: [
          {
            required: true,
            message: "请选择所属行业类型",
            trigger: "change",
          },
        ],
      },
      province: [],
      city: [],
      town: [],
      street: [],
      oneindustry: [],
      twoindustry: [],

      humanform: {
        q_zs: "",
        k_zs: "",
        q_zz: "",
        k_zz: "",
        q_jz: "",
        k_jz: "",
        q_ls: "",
        k_ls: "",
        q_wj: "",
        k_wj: "",
        q_lx: "",
        k_lx: "",
        q_gr: "",
        k_gr: "",
        x_bs: "",
        x_ss: "",
        x_bk: "",
        x_dz: "",
        z_gj: "",
        z_zj: "",
        z_cj: "",
        z_jg: "",
        l_one: "",
        l_two: "",
        l_three: "",
        l_four: "",
        qly: "",
        cbrs: "",
        jzrs: "",
        lsrs: "",
        qyzrs: "",
        qksm: "",
        com_id: "",
      },
      humanRule: {},
      //third
      contactform: {
        contact: [
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 1,
          },
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 2,
          },
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 3,
          },
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 4,
          },
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 5,
          },
        ],
        com_id: "",
      },
      contactRule: {},
      visitform: {
        // visit: new Array(),
        visit: [],
        custom_manager: "",
        level: "",
        custom_source: "",
        visit_count: "",
        introducer: "",
        com_id: "",
        file_name: "",
        url: "",
        file_id: "",
      },
      url: "",
      visitRule: {
        level: [
          {
            required: true,
            message: "请选择客户级别",
            trigger: "change",
          },
        ],
      },
      fileList: [],
    };
  },
  created () {
    this.getprovince();
    this.getFirstIndustry();
    this.activeName = this.$route.query.name
    this.handleClick()
  },
  watch: {
    "baseform.province_id": {
      handler (val, oval) {
        this.getcity(val);
      },
      deep: true,
    },
    "baseform.city_id": {
      handler (val, oval) {
        this.gettown(val);
      },
      deep: true,
    },
    "baseform.town_id": {
      handler (val, oval) {
        this.getstreet(val);
      },
      deep: true,
    },
    "baseform.industry_one": {
      handler (val, oval) {
        this.getSecondIndustry(val);
      },
      deep: true,
    },
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
    peopleNum: function () {
      let sum = 0;
      let sum1 = parseFloat(this.humanform.q_zz) || 0;
      let sum2 = parseFloat(this.humanform.q_jz) || 0;
      let sum3 = parseFloat(this.humanform.q_ls) || 0;
      let sum4 = parseFloat(this.humanform.q_wj) || 0;
      let sum5 = parseFloat(this.humanform.q_lx) || 0;
      let sum6 = parseFloat(this.humanform.q_gr) || 0;
      sum = sum1 + sum2 + sum3 + sum4 + sum5 + sum6;
      console.log(111);
      return sum || 0;
    },
    humanNum: function () {
      let sum = 0;
      let sum1 = parseFloat(this.humanform.k_zz) || 0;
      let sum2 = parseFloat(this.humanform.k_jz) || 0;
      let sum3 = parseFloat(this.humanform.k_ls) || 0;
      let sum4 = parseFloat(this.humanform.k_wj) || 0;
      let sum5 = parseFloat(this.humanform.k_lx) || 0;
      let sum6 = parseFloat(this.humanform.k_gr) || 0;
      sum = sum1 + sum2 + sum3 + sum4 + sum5 + sum6;
      console.log(111);
      return sum || 0;
    },
  },

  mounted () {
    if (this.meber_id) {
      // 这个是一个页面查看的接口
      this.axios
        .get("/api/company/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          this.baseform = res.data;
          this.baseStatus = res.data.is_high
        });
    }
  },
  methods: {
    //是否高企 radio按钮
    isBase (a) {
      this.baseStatus = a
    },
    //first
    baseSubmit () {
      this.$refs.baseRule.validate((vaild) => {
        this.baseform.is_cooperation = 1;
        if (vaild) {
          this.axios
            .post("/api/company/store", this.baseform)
            .then((res) => {
              this.humanform.com_id = res.data.id;
              this.contactform.com_id = res.data.id;
              this.visitform.com_id = res.data.id;
              console.log(this.humanform.com_id);
              console.log(this.visitform.com_id);
              console.log(this.contactform.com_id);
              localStorage.setItem("getcomid", res.data.id);
              this.$message({
                type: "success",
                message: "保存成功",
              });
              this.isDisable = true;
              this.$router.go(-1)
            })
            .catch((res) => {
              console.log("err:", res.message);
            });
        }
      });
    },
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
        });
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
        });
    },
    getstreet (id) {
      this.axios
        .get("/api/region/getBlock", {
          params: {
            town_id: id,
          },
        })
        .then((res) => {
          this.street = res.data;
        });
    },
    getFirstIndustry () {
      this.axios.get("/api/region/getFirstIndustry").then((res) => {
        this.oneindustry = res.data;
      });
    },
    getSecondIndustry (id) {
      this.axios
        .get("/api/region/getSecondIndustry", {
          params: {
            industry_id: id,
          },
        })
        .then((res) => {
          this.twoindustry = res.data;
        });
    },
    //second
    humanSubmit () {
      this.$refs.humanRule.validate((vaild) => {
        if (vaild) {
          this.humanform.q_zs = this.peopleNum;
          this.humanform.k_zs = this.humanNum;
          this.axios
            .post("/api/company/store_human", this.humanform)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功",
              });
              this.isDisable = true;
              this.$router.go(-1)
            })
            .catch((res) => {
              console.log("err:", res);
            });
        }
      });
    },
    handleClick (tab, event) {
      if (this.activeName == "human" && this.meber_id) {
        this.axios
          .get("/api/company/view_relation", {
            params: {
              id: this.meber_id,
              type: this.activeName,
            },
          })
          .then((res) => {
            console.log(this.meber_id);
            console.log(res.data);
            (this.humanform = res.data ? res.data : {}),
              (this.humanform.com_id = this.meber_id);
          });
      } else if (this.activeName == "contact" && this.meber_id) {
        console.log(this.activeName);
        this.axios
          .get("/api/company/view_relation", {
            params: {
              id: this.meber_id,
              type: this.activeName,
            },
          })
          .then((res) => {
            // this.$forceUpdate()
            const dafult_com_man = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 1,
            };
            const dafult_com_cont = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 2,
            };
            const dafult_pro_man = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 3,
            };
            const dafult_fin_man = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 4,
            };
            const dafult_tech_man = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 5,
            };
            this.contactform.com_id = this.meber_id;
          });
      } else if (this.activeName == "business" && this.meber_id) {
        this.visitform.com_id = this.meber_id;
        this.axios
          .get("/api/company/view_relation", {
            params: {
              id: this.meber_id,
              type: this.activeName,
            },
          })
          .then((res) => {
            this.visitform = res.data;
            console.log(this.visitform);
            this.visitform.com_id = this.meber_id;
            this.visitform.visit.forEach((item) => { });
          });
      }
    },
    // save () {
    //   //这部分应该是保存提交你添加的内容
    //   console.log(JSON.stringify(this.visitform.visit));
    // },
  },
};
</script>

<style scoped="scoped">
.Partner dl {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.Partner dl dt {
  display: inline-block;
  width: 25%;
  color: #a6abc7;
}

.Partner dl dd {
  display: inline-block;
  width: 25%;
  margin: 0 !important;
  font-weight: bold;
}

.Partner dl dt:nth-child(2) {
  margin-right: 2% !important;
}

.Partner dl dd:nth-child(2) {
  margin-right: 2% !important;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.Import {
  float: right;
  color: #909399;
  background: #edeef4;
  border: none;
  margin-right: 2%;
}
.fileUpload {
  background: #f2f3f9 !important;
  border-radius: 2px;
  color: #0f38ff !important;
  border: none;
  font-weight: bold;
  padding: 7px 15px;
  font-size: 12px;
  margin-right: 20px;
}
.upload-demo {
  display: inline-block;
}
</style>
